<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" class="cur-a">企业信息认证</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="centerBox">
          <h3 class="centerTitle">企业认证</h3>
          <el-form
              :model="baseform"
              :rules="rules"
              ref="baseform"
              label-width="72px"
          >
            <el-row>
              <el-col :span="13">
                <div style="clear: both">
                  <el-form-item label="公司名称">
                    {{baseform.companyName}}
                  </el-form-item>
                  <el-form-item label="信用代码">
                    {{baseform.companyId}}
                  </el-form-item>
                  <el-form-item
                      class="uploadFormItem"
                      prop="businessLicense"
                      label="营业执照"
                  >
                    <el-upload
                        :on-change="handleAvatarSuccessBusinessLicense"
                        :before-upload="$beforeAvatarUpload"
                        :http-request="$requestMine"
                        :show-file-list="false"
                        class="img-el-upload"
                        action
                        accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                          :src="
                                baseform.businessLicense ||
                                require('@/assets/yingyezhizhao.png')
                              "
                          fit="contain"
                          class="imgCenter"
                      ></el-image>
                    </el-upload>
                  </el-form-item>
                  <el-form-item
                      class="uploadFormItem"
                      label="营业执照上传注意事项"
                  >
                    <p>1.支持jpg、jpeg、png格式，大小不超过5Ｍ;</p>
                    <p>2.证件完整清晰无缺失，不能为电子版或截屏;</p>
                    <p>3.不能添加水印或备注。</p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="11">
                <div class="exampleBg">
                  <el-form-item
                      class="uploadFormItem"
                      label="营业执照示例"
                  >
                    <div class="imageBox">
                      <el-image
                          :src="require('@/assets/exampleImg.png')"
                          fit="contain"
                          class="imgCenter"
                      ></el-image>
                    </div>
                  </el-form-item>
                </div>
              </el-col>



            </el-row>
          </el-form>
          <div class="btn-box">
            <el-button type="primary" class="bgc-bv" @click="submitForm"
            >提交认证</el-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import PubSub from "pubsub.js";

export default {
  name: "enterpriseCertification",
  components: {

  },
  data() {
    return {
      baseform: {
        companyName: "",
        companyId: "",
        businessLicense: "", // 营业执照
        businessLicensePath: "", //营业执照 key
      },
      rules: {
        businessLicense: [{ required: true, trigger: "change", message: "请上传营业执照" }],
      },
      // 认证信息
      authInfo:{}
    };
  },
  computed: {

  },
  created() {
    this.$nextTick(() => {
      this.baseform.companyName = this.$route.query.authInfo.compName
      this.baseform.companyId = this.$route.query.authInfo.compNo
    })

  },
  methods: {
    //身份证人像面图片上传
    handleAvatarSuccessCardFront(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.baseform.idCardFront = result.data.fileURL;
            this.baseform.idCardFrontPath = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
    },
    //营业执照图片上传
    handleAvatarSuccessBusinessLicense(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
          ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
              res.raw.type
          ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
            .then((result) => {
              this.baseform.businessLicense = result.data.fileURL;
              this.baseform.businessLicensePath = result.data.fileKey;
            })
            .catch(() => {
              setTimeout(() => {
                this.$message({
                  type: "warning",
                  message: "上传失败",
                });
              }, 300);
            });
      }
    },
    // 提交认证
    submitForm(){
      const baseform = this.$refs["baseform"];
      baseform.validate((valid) => {
        if (valid) {
          let params = {
            compLicense: this.baseform.businessLicensePath
          }
          this.$post("/hr/auth/comp", params)
              .then((res) => {
                if (res.status == "0") {
                  this.$message({
                    type: "success",
                    message: "提交成功",
                  });
                  PubSub.publish("authCallback");
                  this.checkAuthInfo();
                }
              })
              .catch(() => {
                return;
              });
        }
      })
    },
    // 检测有没有个人认证
    checkAuthInfo(){
        this.$post("/hr/auth/info",{}).then(res=>{
            const {userAuthState}=res.data;
            this.authInfo=res.data;
            // 10 未认证
            if(userAuthState==10){
                this.$confirm('您尚未完成个人认证，请完成个人认证。', '温馨提示', {
                    confirmButtonText: '认证',
                    cancelButtonText: '取消',
                    type: 'warning',
                    showClose:false,
                    closeOnClickModal:false
                }).then(() => {
                    this.goUserAuth();
                }).catch(()=>{
                    this.$router.push("/azhr/home");
                });
                // 40 驳回
            }else{
                this.$router.push("/azhr/home");
            }
        }).catch(e=>{
            console.error("获取认证信息出错",e);
        });
    },
    // 去往个人认证
    goUserAuth(){
        const {authInfo}=this;
        this.$router.push({
            path:"/azhr/humanResources/personalCertification",
            query:{
                authInfo
            }
        });
    }
  },
  // beforeRouteLeave: resetKeepAlive,
  watch: {
    // $route: {
    // 深度观察监听
    // deep: true,
    // },
  },
};
</script>
<style lang="less" scoped>
.framePage-body{
  background: transparent;
  position: relative;
}
.centerBox{
  min-width: 866px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
}
.centerTitle{
  text-align: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
}
.centerDesc{
  text-align: center;
  font-size: 12px;
  color: #999;
  padding-bottom: 14px;
}
.formTitle{
  margin-top: 16px;
  margin-bottom: 30px;
}
/deep/.el-upload{
  border: 0;
  //margin: 0 auto;
  width: 238px;
  height: 169px;
}
/deep/.el-form-item{
  //margin-left: 30px;
  //margin-right: 60px;
  margin-bottom: 0;
}

/deep/.el-form-item__label{
  text-align: left;
}
/deep/.uploadFormItem{
  //float: left;
  //width: 50%;
  margin: 0 0 10px;
  .el-form-item__label{
    float: none;
    width: 100% !important;
  }
  .el-form-item__content{
    margin-left: 0!important;
  }
  &.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
    content: '';
    margin: 0;
  }
  p{
    line-height: 16px;
    font-size: 12px;
    color: #999;
  }
}
.btn-box{
  text-align: center;
  .el-button{
    margin-top: 30px;
    width: 246px;
    padding-top: 0;
    padding-bottom: 0;
    height: 34px;
    line-height: 34px;
  }
}
.exampleBg{
  background: #FFFDF8;
  padding: 84px 60px 96px;
}
.imageBox{
  width: 240px;
  height: 171px;
}
</style>
